export const backgroundCheckingStatus: {
  label: string;
  btnClass: string;
  class: string;
}[] = [
  {
    label: 'Process initiated',
    btnClass: 'v-btn-warning',
    class: 'lnr lnr-hourglass'
  },
  {
    label: 'In progress',
    btnClass: 'v-btn-warning',
    class: 'lnr lnr-hourglass v-text-warning'
  },
  {
    label: 'Completed',
    btnClass: 'v-btn-success',
    class: 'lnr lnr-check v-text-success'
  },
  {
    label: 'Completed (Clear)',
    btnClass: 'v-btn-success',
    class: 'lnr lnr-check v-text-success'
  },
  {
    label: 'Completed (Not Clear)',
    btnClass: 'v-btn-warning',
    class: 'lnr lnr-cross v-text-danger'
  }
];

export enum Providers {
  Mintz = 'mintz',
  ValidityScreening = 'validity_screening'
}
