import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAnalytics } from '@shared/interfaces/analytics.interface';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IEmail } from '@shared/interfaces/email.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPage } from '@shared/interfaces/page.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IReferenceRelationship } from '@shared/interfaces/reference-relationship.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { IText } from '@shared/interfaces/text.interface';
import { ITypeahead } from '@shared/interfaces/typeahead.interface';
import { Analytics } from '@shared/models/analytics.model';
import { Candidate } from '@shared/models/candidate.model';
import { Email } from '@shared/models/email.model';
import { HiringFirm } from '@shared/models/hiring-firm.model';
import { Page } from '@shared/models/page.model';
import { Recruiter } from '@shared/models/recruiter.model';
import { ReferenceRelationship } from '@shared/models/reference-relationship.model';
import { Reference } from '@shared/models/reference.model';
import { Setting } from '@shared/models/setting.model';
import { Text } from '@shared/models/text.model';
import { Typeahead } from '@shared/models/typeahead.model';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import jwtDecode from 'jwt-decode';
import { SessionStorage } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

// TO DO: RENAME TO COMPANIES
@Injectable({
  providedIn: 'root'
})
export class HiringFirmsService implements IPaginable {
  @SessionStorage() public referenceRelationships: IReferenceRelationship[];
  @SessionStorage() public settings: ISetting;

  @SessionStorage() private hiringFirm: IHiringFirm;

  public isDownloading = false;
  public downloadAnalyticsSource = new Subject();
  public downloadAnalyticsCalled$ = this.downloadAnalyticsSource.asObservable();

  private readonly ENDPOINT_V1: string = String(
    `${environment.ENDPOINTS.BACK_END.V1}/hiring_firms`
  );

  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/hiring_firms`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public downloadAnalytics(format: string) {
    this.isDownloading = true;
    this.downloadAnalyticsSource.next(format);
  }

  public delete(id: string): Observable<IHiringFirm> {
    return this._http
      .delete<IAPIData>(`${this.ENDPOINT_V1}/${id}`)
      .pipe(map((res: IAPIData) => HiringFirm.fromAPI(res)));
  }

  public find(id: string, isSaved: boolean = false): Observable<IHiringFirm> {
    return this._http.get<IAPIData>(`${this.ENDPOINT_V1}/${id}`).pipe(
      map((res: IAPIData) => {
        const hf = (this.hiringFirm = HiringFirm.fromAPI(res));

        if (isSaved) {
          this.hiringFirm = hf;
          this.settings = this.hiringFirm.settings;
        }

        return hf;
      })
    );
  }

  public get(): Observable<IHiringFirm[]> {
    return this._http
      .get<IAPIArrayData>(this.ENDPOINT_V1)
      .pipe(map((res: IAPIArrayData) => HiringFirm.fromAPIArray(res)));
  }

  public getAnalytics(
    id: APIuuid,
    query?: {
      start_date?: string;
      end_date?: string;
      scope?: string;
      type: string;
    }
  ): Observable<IAnalytics> {
    let params: HttpParams = new HttpParams();

    if (!!query) {
      Object.keys(query).map(
        (key: string) => (params = params.set(key, String(query[key])))
      );
    }

    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V1}/${id}/analytics`, {
        params
      })
      .pipe(
        map((hiringFirmData: IAPIData) => {
          const analytics = Analytics.fromAPI(hiringFirmData);

          const included = hiringFirmData?.included;
          analytics.recruiters =
            (included &&
              included
                .filter((i: IAPIDataObject) => i.type === 'Recruiter')
                .map((r: IAPIDataObject) =>
                  Recruiter.fromAPI({
                    data: r,
                    included
                  })
                )) ||
            [];

          return analytics;
        })
      );
  }

  public getPerformances(
    id: APIuuid,
    query?: { start_date: string; end_date: string }
  ): Observable<any> {
    let params: HttpParams = new HttpParams();

    if (!!query) {
      Object.keys(query).map(
        (key: string) => (params = params.set(key, String(query[key])))
      );
    }

    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V1}/${id}/performances`, {
        params
      })
      .pipe(map((res: IAPIData) => res));
  }

  public getCandidates(id: string): Observable<ICandidate[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/candidates`)
      .pipe(map((res: IAPIArrayData) => Candidate.fromAPIArray(res)));
  }

  public getEmails(id: string): Observable<IEmail[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/emails`)
      .pipe(map((res: IAPIArrayData) => Email.fromAPIArray(res)));
  }

  public getPages(id: string): Observable<IPage[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/pages`)
      .pipe(map((res: IAPIArrayData) => Page.fromAPIArray(res)));
  }

  public getTexts(id: string): Observable<IText[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/texts`)
      .pipe(map((res: IAPIArrayData) => Text.fromAPIArray(res)));
  }

  public getLeads(id: string): Observable<IReference[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/leads`)
      .pipe(map((res: IAPIArrayData) => Reference.fromAPIArray(res)));
  }

  public getStatistics(id: string, q: any): Observable<IAnalytics> {
    let params: HttpParams = new HttpParams();
    params = params.set('start_date', String(q.start_date));
    params = params.set('end_date', String(q.end_date));
    params = params.set('scope', String(q.scope));

    return this._http
      .get<IAPIData>(`${this.ENDPOINT_V1}/${id}/statistics`, { params })
      .pipe(map((res: IAPIData) => Analytics.fromAPI(res)));
  }

  public getPage(
    page: number,
    onGetRawData?: (data: IAPIArrayData) => void,
    filter?: IFilter
  ): Observable<IHiringFirm[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page[number]', String(page));

    if (!!filter && !!filter['key']) {
      params = params.set(`filter[${filter['key']}]`, filter['value']);
    }

    return this._http.get<IAPIArrayData>(this.ENDPOINT_V1, { params }).pipe(
      tap((data: IAPIArrayData) => onGetRawData && onGetRawData(data)),
      map((res: IAPIArrayData) => HiringFirm.fromAPIArray(res))
    );
  }

  public getRecruiters(id: string): Observable<IRecruiter[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/recruiters`)
      .pipe(map((res: IAPIArrayData) => Recruiter.fromAPIArray(res)));
  }

  public getSettings(id: string): Observable<ISetting[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/settings`)
      .pipe(map((res: IAPIArrayData) => Setting.fromAPIArray(res)));
  }

  public getReferenceRelationships(
    id: string,
    isSaved: boolean = false
  ): Observable<IReferenceRelationship[]> {
    return this._http
      .get<IAPIArrayData>(`${this.ENDPOINT_V3}/${id}/reference_relationships`)
      .pipe(
        map((res: IAPIArrayData) => {
          const rr = ReferenceRelationship.fromAPIArray(res);

          if (isSaved) {
            this.referenceRelationships = rr;
          }

          return rr;
        })
      );
  }

  public patch(body: IAPIData) {
    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V1}/${body.data.id}`, body)
      .pipe(map((res: IAPIData) => HiringFirm.fromAPI(res)));
  }

  public patchLogo(id: string, logo: File) {
    const formData: FormData = new FormData();
    formData.append('logo_file', logo, uuid());

    return this._http
      .patch<IAPIData>(`${this.ENDPOINT_V1}/${id}/logo`, formData)
      .pipe(map((res: IAPIData) => HiringFirm.fromAPI(res)));
  }

  public post(body: IAPIData) {
    return this._http
      .post<IAPIData>(
        `${environment.ENDPOINTS.BACK_END.V1}/hiring_firms_with_root`,
        body
      )
      .pipe(map((res: IAPIData) => HiringFirm.fromAPI(res)));
  }

  public postChildren(id: string, body: any) {
    const params = {
      data: {
        relationships: {
          recruiter: body.recruiter,
          hiring_firm: body.hiring_firm
        }
      }
    };

    return this._http
      .post<IAPIData>(`${this.ENDPOINT_V1}/${id}/children`, params)
      .pipe(map((res: IAPIData) => HiringFirm.fromAPI(res)));
  }

  public searchTypeahead(searchCriteria: {
    data: { search_criteria: string };
  }): Observable<ITypeahead> {
    return this._http
      .post<IAPIData>(`${this.ENDPOINT_V1}/search`, searchCriteria)
      .pipe(map((res: IAPIData) => Typeahead.fromAPI(res)));
  }

  public resolve(): Observable<IHiringFirm> {
    const token = this._commonEnvironments.getToken();
    const payload: IPayload = jwtDecode(token);
    return this.find(payload.hiring_firm_id, true);
  }
}
